import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Box, Typography } from "@mui/material"
import { Section, Segment } from "../Layout"

const HomeHeroMobile = () => {
  return (
    <>
      <div style={{ display: "grid" }}>
        <StaticImage
          style={{
            gridArea: "1/1",
          }}
          layout="fullWidth"
          aspectRatio={2 / 1}
          alt=""
          src="../../images/hero-home.jpg"
          formats={["auto", "webp", "avif"]}
        />
        <div
          style={{
            gridArea: "1/1",
            position: "relative",

            justifyItems: "center",
            alignItems: "flex-start",
            display: "grid",
          }}
        >
          <Section>
            <Box mt="4rem">
              <Typography variant="h2" color="common.white" align="center">
                Innovative Elegance:
              </Typography>
              <Typography variant="h4" color="secondary" align="center">
                Hygenic Utensils for Healthy Living
              </Typography>
            </Box>
          </Section>
        </div>
      </div>
    </>
  )
}

export default HomeHeroMobile
