import React from "react"
import PropTypes from "prop-types"
import { Container, Grid, Box, Typography, useMediaQuery } from "@mui/material"
import { useTheme } from "@mui/material/styles"
import { StaticImage } from "gatsby-plugin-image"
import ReactPlayer from "react-player"
import Gallery from "react-photo-gallery"

import { ThemeScrollAnimation } from "../ThemeScrollAnimation"
import { Segment, Section, Center } from "../Layout"
import { SectionTitle } from "../SectionTitle"
import { SegmentTitle } from "../SegmentTitle"

import ImgGal01 from "../../images/gallery-touchless-spirit/01.jpg"
import ImgGal02 from "../../images/gallery-touchless-spirit/02.jpg"
import ImgGal03 from "../../images/gallery-touchless-spirit/03.jpg"

const photosChopsticks = [
  {
    src: ImgGal01,
    width: 5,
    height: 3,
  },
  {
    src: ImgGal02,
    width: 5,
    height: 3,
  },
  {
    src: ImgGal03,
    width: 5,
    height: 3,
  },
]

const TouchlessSpirit = ({ children }) => {
  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.between("xs", "md"))

  return (
    <>
      <div style={{ display: "grid" }}>
        <StaticImage
          style={{
            gridArea: "1/1",
            maxHeight: 600,
          }}
          layout="fullWidth"
          aspectRatio={2 / 1}
          alt=""
          src="../../images/hero-touchlessspirit.jpg"
          formats={["auto", "webp", "avif"]}
        />
        <div
          style={{
            gridArea: "1/1",
            position: "relative",
            justifyItems: "center",
            alignItems: "center",
            display: "grid",
          }}
        >
          <Section>
            <ThemeScrollAnimation animateIn="fadeInUp">
              <Box>
                <Typography variant="h2" color="common.white" align="center">
                  Touchless Spirit!
                </Typography>
                <Typography variant="h4" color="secondary.light" align="center">
                  A new way of living!
                </Typography>
              </Box>
            </ThemeScrollAnimation>
          </Section>
        </div>
      </div>
      <Segment>
        <Container disableGutters>
          {/* <ThemeScrollAnimation animateIn="fadeIn">
            <Box>
              <SegmentTitle>Touchless Spirit</SegmentTitle>
            </Box>
          </ThemeScrollAnimation> */}
          <Section>
            <Grid container>
              <Grid item xs={12}>
                <Box>
                  <ThemeScrollAnimation animateIn="fadeInUp">
                    <SectionTitle align="center">The Story</SectionTitle>
                    <SectionTitle align="center" subtitle gutterBottom>
                      Creator & Entrepreneur
                    </SectionTitle>
                  </ThemeScrollAnimation>
                  <ThemeScrollAnimation animateIn="fadeInUp">
                    <Typography
                      align="center"
                      pr={isSmall ? 0 : "64px"}
                      paragraph
                    >
                      Dr. Michael S. Joyner, a Hand and Microsurgeon, is the
                      creator and founder of the Touchless Flatware Patented
                      Designs. As a surgeon and health care professional, Dr.
                      Joyner understands the importance of germ prevention.
                      Whether in the operating room, doctor's office, hospital
                      cafeteria, or sitting at a restaurant tabletop, germ
                      prevention is important. Touchless Flatware is designed in
                      order to help avoid the germs on tabletops and kitchen
                      counters.
                    </Typography>
                    <Typography
                      align="center"
                      pr={isSmall ? 0 : "64px"}
                      paragraph
                    >
                      While dining in a restaurant, like many restaurant
                      patrons, "Doc J" wanted to place his flatware on the
                      restaurant tabletop in order to avoid the germs that he
                      knew were on the table. However, there was no napkin, dish
                      or place setting available to set his flatware. He
                      suddenly envisioned the new unique germ resistant
                      Touchless Flatware patented design. This new Touchless
                      modern American design is unique in that when upright the
                      mouthpiece end avoids touching the table.
                    </Typography>
                    <Typography
                      align="center"
                      pr={isSmall ? 0 : "64px"}
                      paragraph
                    >
                      Touchless Flatware and the Touchless Philosophy combine
                      royalty, science, and art with simplicity. Spread the
                      word. "Go Touchless ... Touchless is the new way to dine!"
                    </Typography>
                  </ThemeScrollAnimation>
                  <ThemeScrollAnimation animateIn="fadeInUp">
                    <Box mt={2}>
                      <Center>
                        <ReactPlayer url="https://youtu.be/J8Ht85cyyFc" />
                      </Center>
                    </Box>
                  </ThemeScrollAnimation>
                </Box>
              </Grid>
            </Grid>
          </Section>
        </Container>
      </Segment>
      <Segment variant="alternate">
        <Container disableGutters>
          <Section>
            <Grid container>
              <Grid item xs={12}>
                <Box>
                  <ThemeScrollAnimation animateIn="fadeInUp">
                    <Box mb="4rem">
                      <SectionTitle align="center">
                        The Patents & Design
                      </SectionTitle>
                    </Box>
                  </ThemeScrollAnimation>
                  <Grid container spacing={2} mb="8rem">
                    <Grid item xs={12} md={6}>
                      <ThemeScrollAnimation animateIn="fadeInUp">
                        <SectionTitle
                          align={isSmall ? "center" : "right"}
                          subtitle
                          gutterBottom
                        >
                          Elevated Distal Ends
                        </SectionTitle>
                      </ThemeScrollAnimation>
                      <ThemeScrollAnimation animateIn="fadeInUp">
                        <Typography
                          align={isSmall ? "center" : "right"}
                          paragraph
                          gutterBottom
                        >
                          The Touchless US and International Patent pending
                          technology and designs highlight utensils and
                          chopsticks having elevated distal ends for germ
                          prevention. Touchless Modern American designs avoid
                          the germs on tables and countertops. Stainless steel,
                          plasticware, and children's designs are patent
                          pending. It's the new way to dine. Go Touchless!
                        </Typography>
                      </ThemeScrollAnimation>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <ThemeScrollAnimation animateIn="fadeInUp">
                        <Center>
                          <StaticImage
                            src="../../images/img-fork.jpg"
                            alt="touchless fork"
                            placeholder="blurred"
                            width={500}
                          />
                        </Center>
                      </ThemeScrollAnimation>
                    </Grid>
                  </Grid>
                  <Grid container spacign={2} mb="8rem">
                    <Grid item xs={12}>
                      <ThemeScrollAnimation animateIn="fadeInUp">
                        <Gallery photos={photosChopsticks} />
                      </ThemeScrollAnimation>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <ThemeScrollAnimation animateIn="fadeInUp">
                        <Center>
                          <StaticImage
                            src="../../images/img-ergonomic.jpg"
                            alt="hands cutting food with touchless flatware"
                            placeholder="blurred"
                            width={500}
                          />
                        </Center>
                      </ThemeScrollAnimation>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <ThemeScrollAnimation animateIn="fadeInUp">
                        <SectionTitle
                          align={isSmall ? "center" : "initial"}
                          subtitle
                          gutterBottom
                        >
                          Eco-designs with an Ergonomic Orientation
                        </SectionTitle>
                      </ThemeScrollAnimation>
                      <ThemeScrollAnimation animateIn="fadeInUp">
                        <Typography
                          align={isSmall ? "center" : "initial"}
                          paragraph
                          gutterBottom
                        >
                          Avoid touching the table. Touchless Flatware offers a
                          unique design that embraces eco-designs with an
                          ergonomic orientation that introduces an innovative
                          germ-free component. Touchless Flatware is unique in
                          that when upright the mouthpiece end never touches the
                          table. This patent-protected and aesthetic design
                          blends a germ-resistant attribute with modernity,
                          functionality along with a celebration of style,
                          timelessness and high quality craftsmanship. Touchless
                          germ-free designs include stainless steel, plastic,
                          and children styles. Touchless Flatware is the new way
                          to dine!
                        </Typography>
                      </ThemeScrollAnimation>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Section>
        </Container>
      </Segment>
    </>
  )
}

TouchlessSpirit.propTypes = {
  children: PropTypes.node,
}

export default TouchlessSpirit
