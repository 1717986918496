import React from "react"
import PropTypes from "prop-types"
import { Container, Grid, Box, Typography, useMediaQuery } from "@mui/material"
import { useTheme } from "@mui/material/styles"
import { StaticImage } from "gatsby-plugin-image"
import Gallery from "react-photo-gallery"

import { ThemeScrollAnimation } from "../ThemeScrollAnimation"
import { Segment, Section } from "../Layout"
import { SectionTitle } from "../SectionTitle"
import { SegmentTitle } from "../SegmentTitle"
import { ThemeButton } from "../ThemeButton"

import LogoTouchless from "../../images/logo-flatware.svg"

import FlatwareGal01 from "../../images/gallery/01.jpg"
import FlatwareGal02 from "../../images/gallery/02.jpg"
import FlatwareGal03 from "../../images/gallery/03.jpg"
import FlatwareGal04 from "../../images/gallery/04.jpg"
import FlatwareGal05 from "../../images/gallery/05.jpg"

const photosFlatware = [
  {
    src: FlatwareGal01,
    width: 4,
    height: 3,
  },

  {
    src: FlatwareGal04,
    width: 4,
    height: 3,
  },
  {
    src: FlatwareGal03,
    width: 4,
    height: 3,
  },

  {
    src: FlatwareGal05,
    width: 3,
    height: 3,
  },
]

const FlatwareChopsticks = ({ children }) => {
  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.between("xs", "md"))

  return (
    <>
      <div style={{ display: "grid" }}>
        <StaticImage
          style={{
            gridArea: "1/1",
            maxHeight: 600,
          }}
          layout="fullWidth"
          aspectRatio={2 / 1}
          alt=""
          src="../../images/hero-flatwarechopsticks.jpg"
          formats={["auto", "webp", "avif"]}
        />
        <div
          style={{
            gridArea: "1/1",
            position: "relative",
            justifyItems: "center",
            alignItems: "flex-start",
            display: "grid",
          }}
        >
          <Section>
            <ThemeScrollAnimation animateIn="fadeInUp">
              <Box mt={isSmall ? "4rem" : "8rem"}>
                <Typography variant="h2" color="common.white" align="center">
                  Touchless Flatware
                </Typography>
                <Typography variant="h4" color="common.white" align="center">
                  Innovative Elegance
                </Typography>
              </Box>
            </ThemeScrollAnimation>
          </Section>
        </div>
      </div>
      <Segment>
        <Container disableGutters>
          {/* <ThemeScrollAnimation animateIn="fadeIn">
            <Box>
              <SegmentTitle>Flatware & Chopsticks</SegmentTitle>
            </Box>
          </ThemeScrollAnimation> */}
          <Section>
            <Grid container>
              <Grid item xs={12} md={5}>
                <ThemeScrollAnimation animateIn="fadeInUp">
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      pr: isSmall ? 0 : 4,
                    }}
                    mb={isSmall ? 4 : 0}
                  >
                    <Box
                      component="img"
                      src={LogoTouchless}
                      alt="logo touchless"
                    />
                  </Box>
                </ThemeScrollAnimation>
              </Grid>
              <Grid item xs={12} md={7}>
                <Box>
                  <ThemeScrollAnimation animateIn="fadeInUp">
                    <SectionTitle align={isSmall ? "center" : "initial"}>
                      Touchless Flatware
                    </SectionTitle>
                    <SectionTitle
                      align={isSmall ? "center" : "initial"}
                      subtitle
                      gutterBottom
                    >
                      Avoids Touching the Table
                    </SectionTitle>
                  </ThemeScrollAnimation>
                  <ThemeScrollAnimation animateIn="fadeInUp">
                    <Typography
                      align={isSmall ? "center" : "initial"}
                      pr={isSmall ? 0 : "64px"}
                      paragraph
                    >
                      This new Touchless modern American design is unique in
                      that when upright the mouthpiece end avoids touching the
                      table. Touchless Flatware and the Touchless Philosophy
                      combine royalty, science, and art with simplicity. Spread
                      the word. “Go Touchless … Touchless is the new way to
                      dine!”
                    </Typography>
                    <Box
                      display="flex"
                      justifyContent={isSmall ? "center" : "flex-start"}
                    >
                      <ThemeButton
                        onClick={() =>
                          window.open(
                            "https://www.amazon.com/Touchless-Flatware-TFR20-0-Piece-Stainless/dp/B00AE23JMA/ref=sr_1_1?dchild=1&keywords=touchless%2Bflatware&qid=1590026481&sr=8-1&th=1",
                            "_blank"
                          )
                        }
                      >
                        Shop Touchless Flatware
                      </ThemeButton>
                    </Box>
                  </ThemeScrollAnimation>
                </Box>
              </Grid>
            </Grid>
          </Section>
        </Container>
      </Segment>
      <ThemeScrollAnimation animateIn="fadeInUp">
        <Gallery photos={photosFlatware} />
      </ThemeScrollAnimation>
    </>
  )
}

FlatwareChopsticks.propTypes = {
  children: PropTypes.node,
}

export default FlatwareChopsticks
