import React from "react"
import { rem } from "polished"
import PropTypes from "prop-types"
import { Box, Typography } from "@mui/material"

import { StyledVideoBackground } from "./VideoBackground.styled"

const VideoBackground = ({ children, src, poster, height }) => {
  const videoHeight = `${rem(height)}`
  return (
    <Box sx={{ height: videoHeight, position: "relative" }}>
      <Box
        sx={{
          zIndex: 3,
          height: videoHeight,
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          position: "relative",
        }}
      >
        {children}
      </Box>

      <StyledVideoBackground>
        <video width="100%" loop autoPlay muted id="bg-video" poster={poster}>
          <source src={src} type="video/mp4" />
        </video>
      </StyledVideoBackground>
      {/* <Box>
        <Box
          component="img"
          src={CurvySection}
          sx={{ position: "absolute", bottom: 0, width: "100%", zIndex: 2 }}
        />
      </Box> */}
      <Box
        sx={{
          backgroundColor: "rgba(21,0,0,0.5)",
          height: videoHeight,
          width: "100%",
          position: "absolute",
          top: 0,
          left: 0,
          zIndex: 1,
        }}
      />
    </Box>
  )
}

VideoBackground.propTypes = {
  children: PropTypes.node,
  src: PropTypes.string,
  poster: PropTypes.string,
  height: PropTypes.number,
}

export default VideoBackground
