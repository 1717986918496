import React from "react"
import ReactPlayer from "react-player/youtube"
import PropTypes from "prop-types"
import { Container, Grid, Box, Typography, useMediaQuery } from "@mui/material"
import { useTheme } from "@mui/material/styles"
import { StaticImage } from "gatsby-plugin-image"

import { Segment, Section, Center } from "../Layout"
import { SegmentTitle } from "../SegmentTitle"
import { SectionTitle } from "../SectionTitle"
import { ThemeScrollAnimation } from "../ThemeScrollAnimation"
import { ThemeButton } from "../ThemeButton"

const About = ({ children }) => {
  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.between("xs", "md"))

  return (
    <>
      <Segment variant="alternate">
        <Container disableGutters>
          <ThemeScrollAnimation animateIn="fadeInUp">
            <Box>
              <SegmentTitle>ABOUT</SegmentTitle>
            </Box>
          </ThemeScrollAnimation>
          <Section>
            <ThemeScrollAnimation animateIn="fadeInUp">
              <Grid container>
                <Grid item xs={12}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      pr: isSmall ? 0 : 4,
                    }}
                    mb={isSmall ? 4 : 0}
                  >
                    <StaticImage
                      src="../../images/img-us-army.png"
                      alt="us army logo"
                      placeholder="blurred"
                      width={500}
                    />
                  </Box>
                </Grid>
              </Grid>
            </ThemeScrollAnimation>
            <Grid container>
              <Grid item xs={12}>
                <Box>
                  <ThemeScrollAnimation animateIn="fadeInUp">
                    <SectionTitle align="center">
                      Veteran Owned Business
                    </SectionTitle>
                    <SectionTitle align="center" subtitle gutterBottom>
                      Inventor and Hand & Microsurgeon
                    </SectionTitle>
                  </ThemeScrollAnimation>
                  <ThemeScrollAnimation animateIn="fadeInUp">
                    <Typography align="center" paragraph gutterBottom>
                      Major Michael S. Joyner, MD, an inventor and Hand &
                      Microsurgeon in private practice located in Boca Raton,
                      Florida, is the creator and founder of the Touchless
                      Flatware Corporation and Touchless Patented Designs. Dr.
                      Joyner, aka “Dr. J”, a Los Angeles, California native and
                      Loyola High School Alumnus, received his undergraduate
                      training at UC Berkeley and Howard University. After being
                      awarded a Bachelor of Science degree at Howard University,
                      Dr. J earned his medical degree from Georgetown University
                      School of Medicine in Washington, DC, where he was a
                      United States Army Health Scholarship recipient. He
                      completed his general surgery internship at Walter Reed
                      Army Medical Center where he served active duty as a
                      captain in the United States Army. After surgical
                      internship, Dr. J served as a US Army General Medical
                      Officer and Primary Brigade Surgeon at the Presidio of
                      Monterey Army Health Clinic from 1996 to 2000. He
                      completed his military obligation as a Major in the US
                      Army.
                    </Typography>
                    <Typography align="center" paragraph>
                      Dr. J received his postgraduate training in General
                      Surgery at Morehouse School of Medicine in Atlanta,
                      Georgia. While serving as Chief General Surgery resident
                      at Grady Memorial Hospital. Dr. Joyner became very
                      experienced in Trauma Surgery. He served as a Christin M.
                      Kleinert Hand Surgery Fellow in 2005 and a visiting
                      microsurgical scholar to Chang- Gung Memorial Hospital,
                      Taipei, Taiwan in 2006. He was a staff hand surgeon and
                      clinical instructor at the University of Louisville
                      Kleinert Institute before starting private practice.
                      Touchless Flatware and Touchless Patented Designs were
                      born out of Dr. Joyner’s ability to identify a need and
                      the desire to provide a solution of germ prevention.
                    </Typography>
                  </ThemeScrollAnimation>
                  <ThemeScrollAnimation animateIn="fadeInUp">
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        pr: isSmall ? 0 : 4,
                      }}
                      mb={isSmall ? 4 : 0}
                    >
                      <ReactPlayer url="https://www.youtube.com/embed/2cFeWRlmhM0" />
                    </Box>
                  </ThemeScrollAnimation>
                </Box>
              </Grid>
            </Grid>
          </Section>
        </Container>
      </Segment>
      <Segment variant="secondary">
        <Container disableGutters>
          <Section>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <ThemeScrollAnimation animateIn="fadeInUp">
                  <SectionTitle
                    align={isSmall ? "center" : "initial"}
                    color="common.white"
                  >
                    Hand and Microsurgery
                  </SectionTitle>
                </ThemeScrollAnimation>
                <ThemeScrollAnimation animateIn="fadeInUp">
                  <Typography
                    align={isSmall ? "center" : "initial"}
                    color="common.white"
                    gutterBottom
                  >
                    Do you have carpal tunnel syndrome, arthritis, tennis elbow,
                    locking fingers or hand pain from an injury? Do you want
                    strong and healthy hands? Doctor Joyner specializes in
                    surgical and non-surgical hand care at The Joyner Hand, PA
                    in Delray Beach, Florida.
                  </Typography>
                  <Typography color="common.white">
                    Dr. J, a Los Angeles, California native and graduate from
                    Georgetown Medical School is fellowship trained in Hand and
                    Microsurgery.
                  </Typography>{" "}
                  <Box mt={2}>
                    <Box mb={2}>
                      <Typography color="common.white">
                        Learn more about The Joyner Hand practice.
                      </Typography>
                    </Box>
                    <Box
                      display="flex"
                      justifyContent={isSmall ? "center" : "flex-start"}
                    >
                      <ThemeButton
                        href="http://thejoynerhand.com/"
                        target="_blank"
                        rel="noopener"
                      >
                        Visit The Joyner Hand
                      </ThemeButton>
                    </Box>
                  </Box>
                </ThemeScrollAnimation>
              </Grid>
              <Grid item xs={12} md={6}>
                <Center>
                  <ReactPlayer url="https://www.youtube.com/embed/46IdgiWnLNE" />
                </Center>
              </Grid>
            </Grid>
          </Section>
        </Container>
      </Segment>
      <Segment variant="primary">
        <Container disableGutters>
          <Section>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <ThemeScrollAnimation animateIn="fadeInUp">
                  <SectionTitle
                    color="common.white"
                    align={isSmall ? "center" : "initial"}
                  >
                    Inventor: Making New History
                  </SectionTitle>
                </ThemeScrollAnimation>
                <ThemeScrollAnimation animateIn="fadeInUp">
                  <Typography
                    color="common.white"
                    align={isSmall ? "center" : "initial"}
                    gutterBottom
                  >
                    Dr. Michael J is a true inventor who is passionate about
                    hand surgery. His work revolves around his ultimate goal,
                    which is to make “New History” with his businesses. An
                    exemplification of this new history is his Touchless
                    Flatware, Chopsticks and Dishware.
                  </Typography>
                  <Box
                    mt={2}
                    display="flex"
                    justifyContent={isSmall ? "center" : "flex-start"}
                  >
                    <ThemeButton
                      href="https://www.mjmdglobal.com/"
                      target="_blank"
                      rel="noopener"
                      color="secondary"
                    >
                      Visit DrMichaelJ.com
                    </ThemeButton>
                  </Box>
                </ThemeScrollAnimation>
              </Grid>
              <Grid item xs={12} md={6}>
                <ThemeScrollAnimation animateIn="fadeInUp">
                  <Grid container>
                    <Grid item xs={12}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          pr: isSmall ? 0 : 4,
                        }}
                        mb={isSmall ? 4 : 0}
                      >
                        <StaticImage
                          src="../../images/img-joyner-scrubs.jpg"
                          alt="dr joyner headshot"
                          placeholder="blurred"
                          width={500}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </ThemeScrollAnimation>
              </Grid>
            </Grid>
          </Section>
          <Section>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <ThemeScrollAnimation animateIn="fadeInUp">
                  <Grid container>
                    <Grid item xs={12}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          pr: isSmall ? 0 : 4,
                        }}
                        mb={isSmall ? 4 : 0}
                      >
                        <StaticImage
                          src="../../images/img-book-piles.png"
                          alt="book piles"
                          placeholder="blurred"
                          width={500}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </ThemeScrollAnimation>
              </Grid>
              <Grid item xs={12} md={6}>
                <ThemeScrollAnimation animateIn="fadeInUp">
                  <SectionTitle
                    color="common.white"
                    align={isSmall ? "center" : "right"}
                  >
                    Author and Philanthropist
                  </SectionTitle>
                </ThemeScrollAnimation>
                <ThemeScrollAnimation animateIn="fadeInUp">
                  <Typography
                    color="common.white"
                    align={isSmall ? "center" : "right"}
                    gutterBottom
                  >
                    Not only is Doctor Joyner the author of the Forever My Daddy
                    Book Series but he serves as the president and founder of
                    the Forever My Daddy Grand Foundation, a 501c3 non-profit
                    dedicated to fathers and grandparents who are affected by
                    obstacles such as parental alienation that prevent the
                    development of true loving relations hips with their
                    children and grandchildren. Learn more about programs such
                    as the child or grandchild support gift card program at
                    www.fmdgrand.org.Whether speaking at a college campus,
                    church, assisted living facility or professional groups and
                    organizations, Doctor Joyner has information in his book to
                    share. Dr. J is the author of the book Forever My Daddy:
                    Denied. The book FMD Denied on Kindle and Amazon highlights
                    lessons learned about topics such as premarital courtship,
                    fatherhood, grandparents, private schools, courtrooms, child
                    support and most importantly health and wellbeing. “Lessons
                    learned” promote relationships despite broken marriages and
                    parental or grandparental alienation. Buy the book and learn
                    more at ForeverMyDaddy.com.
                  </Typography>
                  <Box
                    mt={2}
                    display="flex"
                    justifyContent={isSmall ? "center" : "flex-end"}
                  >
                    <ThemeButton
                      href="https://www.authormichaeljoynermd.com/"
                      target="_blank"
                      rel="noopener"
                      color="secondary"
                    >
                      Visit Author Site
                    </ThemeButton>
                  </Box>
                </ThemeScrollAnimation>
              </Grid>
            </Grid>
          </Section>
        </Container>
      </Segment>
    </>
  )
}

About.propTypes = {
  children: PropTypes.node,
}

export default About
