import React from "react"
import { rem } from "polished"
import PropTypes from "prop-types"
import { Box } from "@mui/material"

const Section = ({ children }) => {
  return (
    <Box py={`${rem(64)}`} px={{ xs: 2 }}>
      {children}
    </Box>
  )
}

Section.propTypes = {
  children: PropTypes.node,
}

export default Section
