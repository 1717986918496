import React from "react"
import PropTypes from "prop-types"
import { Box, IconButton } from "@mui/material"
import FacebookIcon from "@mui/icons-material/Facebook"
import InstagramIcon from "@mui/icons-material/Instagram"
import TwitterIcon from "@mui/icons-material/Twitter"

// import LinkedInIcon from "@mui/icons-material/LinkedIn"

const SocialLinks = ({ white, children }) => {
  return (
    <Box display="flex">
      <IconButton
        href="https://www.facebook.com/GoTouchless/"
        target="_blank"
        rel="noopener"
      >
        <FacebookIcon sx={{ color: white ? "white" : "primary" }} />
      </IconButton>
      <IconButton
        href="https://www.instagram.com/docctor_j"
        target="_blank"
        rel="noopener"
      >
        <InstagramIcon sx={{ color: white ? "white" : "primary" }} />
      </IconButton>
      <IconButton
        href="https://twitter.com/Go_Touchless"
        target="_blank"
        rel="noopener"
      >
        <TwitterIcon sx={{ color: white ? "white" : "primary" }} />
      </IconButton>
      {/* <IconButton
        href="https://www.linkedin.com/groups/13979673/"
        target="_blank"
        rel="noopener"
      >
        <LinkedInIcon sx={{ color: white ? "white" : "primary" }} />
      </IconButton> */}
    </Box>
  )
}

SocialLinks.propTypes = {
  children: PropTypes.node,
}

export default SocialLinks
