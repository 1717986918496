import React from "react"
import { Grid, Typography, Box, useTheme, useMediaQuery } from "@mui/material"
import ReactPlayer from "react-player/youtube"
import Gallery from "react-photo-gallery"
import { StaticImage } from "gatsby-plugin-image"
import "react-image-gallery/styles/css/image-gallery.css"

import { ThemeScrollAnimation } from "../ThemeScrollAnimation"
import { Center, Segment, Section } from "../Layout"
import { SectionTitle } from "../SectionTitle"
import HomeHeroMobile from "./HomeHeroMobile"
import HomeHeroDesktop from "./HomeHeroDesktop"

import ImgGal01 from "../../images/gallery-home/gal-home-couple.jpg"
import ImgGal02 from "../../images/gallery-home/gal-home-fork-ergo.jpg"
import ImgGal03 from "../../images/gallery-home/gal-home-woman-chopsticks.jpg"
import ImgGal04 from "../../images/gallery-home/gal-home-spoon.jpg"
import ImgGal05 from "../../images/gallery-home/gal-home-chopsticks.jpg"

const photos = [
  // {
  //   src: ImgGal01,
  //   width: 5,
  //   height: 3,
  // },
  {
    src: ImgGal02,
    width: 5,
    height: 3,
  },
  {
    src: ImgGal05,
    width: 5,
    height: 3,
  },
  {
    src: ImgGal04,
    width: 5,
    height: 3,
  },
]

const Home = () => {
  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.between("xs", "md"))
  return (
    <>
      {isSmall ? <HomeHeroMobile /> : <HomeHeroDesktop />}

      <Segment>
        <Section>
          <Grid container>
            <Grid item xs={12}>
              <ThemeScrollAnimation animateIn="fadeInUp">
                <SectionTitle align="center">What is Touchless?</SectionTitle>
                <SectionTitle subtitle align="center">
                  Innovative Elegance
                </SectionTitle>
              </ThemeScrollAnimation>
              <ThemeScrollAnimation animateIn="fadeInUp">
                <Box mb={2}>
                  <Typography align="center">
                    Touchless Flatware is unique product that when upright the
                    mouthpiece end never touches the table.
                  </Typography>
                </Box>
                <Box>
                  <Center>
                    <ReactPlayer url="www.youtube.com/embed/lP7OedMWTzM" />
                  </Center>
                </Box>
              </ThemeScrollAnimation>
            </Grid>
          </Grid>
        </Section>
      </Segment>
      <ThemeScrollAnimation animateIn="fadeInUp">
        <Gallery photos={photos} />
      </ThemeScrollAnimation>
    </>
  )
}

export default Home
