import React from "react"
import PropTypes from "prop-types"
import { rem } from "polished"
import { Box } from "@mui/material"
import { useTheme } from "@mui/system"

const Segment = ({ variant, children, ...props }) => {
  const theme = useTheme()

  const getVariant = _variant => {
    let variantColor = null
    switch (_variant) {
      case "alternate":
        variantColor = theme.palette.common.lightGrey
        break
      case "primary":
        variantColor = theme.palette.primary.main
        break
      case "secondary":
        variantColor = theme.palette.secondary.dark
        break
      default:
        variantColor = theme.palette.common.white
    }
    return variantColor
  }

  return (
    <Box
      sx={{
        py: `${rem(64)}`,
        backgroundColor: getVariant(variant),
      }}
      {...props}
    >
      {children}
    </Box>
  )
}

Segment.propTypes = {
  alternate: PropTypes.bool,
  children: PropTypes.node,
}

export default Segment
