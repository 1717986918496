import React from "react"
import PropTypes from "prop-types"
import { graphql, StaticQuery } from "gatsby"

import { StyledLayout } from "./Layout.styled"
import { Navigation } from "../Navigation"
import { Footer } from "../Footer"

const Layout = ({ yAxis, children }) => {
  console.log("Layout yAxis: ", yAxis)
  return (
    <StaticQuery
      query={graphql`
        query SiteMenuLinks {
          site {
            siteMetadata {
              menuLinks {
                id
                isActiveLink
                label
                scrollto
              }
            }
          }
        }
      `}
      render={data => {
        return (
          <StyledLayout>
            <Navigation
              menuLinks={data.site.siteMetadata.menuLinks}
              yAxisPositions={yAxis}
            />
            {children}
            <Footer />
          </StyledLayout>
        )
      }}
    />
  )
}

Layout.propTypes = {
  children: PropTypes.node,
}

export default Layout
