import { styled } from "@mui/system"
import { rem } from "polished"
import { Box } from "@mui/material"

const StyledFooter = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  padding: `${rem(32)}`,
  [theme.breakpoints.down("sm")]: {
    paddingBottom: `${rem(32)}`,
  },
}))

export { StyledFooter }
