import React, { useState, useEffect, useCallback } from "react"

import PropTypes from "prop-types"
import { Scrollchor, easeOutQuad } from "react-scrollchor"
import {
  AppBar,
  Box,
  Container,
  IconButton,
  Toolbar,
  Drawer,
  ListItem,
  List,
} from "@mui/material"

import MenuIcon from "@mui/icons-material/Menu"

import { SocialLinks } from "../SocialLinks"
import { SvgLogo } from "../SvgLogo"

import { StyledNavElement } from "./Navigation.styled"

// import { useMenuQuery } from "../../hooks/useMenuQuery"

const Navigation = ({ yAxisPositions, menuLinks }) => {
  const {
    posHome,
    posAbout,
    posContact,
    posTouchlessSpirit,
    posFlatwareChopsticks,
    posChopsticks,
    posGoGreen,
  } = yAxisPositions

  const [yPos, setYPos] = useState(null)
  const [activeLink, setActiveLink] = useState("home")
  const [appBarWhite, setAppBarWhite] = useState(false)
  const [drawerIsOpen, setDrawerIsOpen] = useState(false)
  // const isScrolling = useScrollTrigger

  const openDrawer = () => {
    setDrawerIsOpen(true)
  }

  const handleScroll = useCallback(() => {
    const yAxis = window.pageYOffset
    setYPos(yAxis)
  }, [])

  useEffect(() => {
    // Update the document title using the browser API
    window.addEventListener("scroll", handleScroll)
    return () => {
      if (typeof window !== `undefined`) {
        window.removeEventListener("scroll", handleScroll)
      }
    }
  }, [handleScroll])

  useEffect(() => {
    if (yPos < posAbout) {
      setActiveLink("home")
    }
    if (yPos > posTouchlessSpirit - 150 && yPos < posFlatwareChopsticks) {
      setActiveLink("touchless-spirit")
    }
    if (yPos > posFlatwareChopsticks - 150 && yPos < posChopsticks) {
      setActiveLink("flatware")
    }
    if (yPos > posChopsticks - 150 && yPos < posGoGreen) {
      setActiveLink("chopsticks")
    }
    if (yPos > posGoGreen - 150 && yPos < posAbout) {
      setActiveLink("go-green")
    }
    if (yPos > posAbout - 150 && yPos < posContact) {
      setActiveLink("about")
    }
    if (yPos - 50 >= posContact) {
      setActiveLink("contact")
    }
  }, [
    posHome,
    posAbout,
    posContact,
    posTouchlessSpirit,
    posFlatwareChopsticks,
    posChopsticks,
    posGoGreen,
    yPos,
  ])

  useEffect(() => {
    yPos > 10 ? setAppBarWhite(true) : setAppBarWhite(false)
  }, [yPos])

  return (
    <>
      <AppBar
        position="fixed"
        color="transparent"
        elevation={0}
        sx={{
          backgroundColor: appBarWhite ? "white" : "initial",
          transition: "all 500ms",
        }}
      >
        <Container disableGutters>
          <Toolbar>
            <Box
              sx={{
                flexGrow: {
                  xs: 1,
                  md: 3,
                },
                display: "flex",
                justifyContent: {
                  xs: "flex-start",
                  md: "flex-start",
                },
              }}
            >
              <Scrollchor to="#home">
                <SvgLogo alt="logo go touchless" white={appBarWhite} />
              </Scrollchor>
            </Box>
            <Box
              sx={{
                flexGrow: 1,
                display: {
                  xs: "none",
                  sm: "none",
                  md: "block",
                  lg: "block",
                  xl: "block",
                },
              }}
            >
              <Box>
                <Box display="flex" justifyContent="space-between">
                  {menuLinks.map(({ id, label, scrollto }) => (
                    <StyledNavElement
                      to={scrollto}
                      key={id}
                      animate={{ duration: 1000, easing: easeOutQuad }}
                      isactivelink={activeLink === scrollto.slice(1)}
                    >
                      {label}
                    </StyledNavElement>
                  ))}
                </Box>
                {/* <SocialLinks /> */}
              </Box>
            </Box>
            <Box
              sx={{
                display: {
                  xs: "flex",
                  sm: "flex",
                  md: "none",
                  lg: "none",
                },
              }}
            >
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="open drawer"
                onClick={openDrawer}
              >
                <MenuIcon />
              </IconButton>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <Drawer
        anchor="right"
        open={drawerIsOpen}
        onClose={() => setDrawerIsOpen(false)}
      >
        <List>
          <ListItem>
            <StyledNavElement
              to="#home"
              animate={{ duration: 1000, easing: easeOutQuad }}
              isactivelink={activeLink === "home"}
              beforeAnimate={() => setDrawerIsOpen(false)}
            >
              Home
            </StyledNavElement>
          </ListItem>
          {menuLinks.map(({ id, label, scrollto }) => (
            <ListItem key={id}>
              <StyledNavElement
                to={scrollto}
                animate={{ duration: 1000, easing: easeOutQuad }}
                isactivelink={activeLink === scrollto.slice(1)}
                beforeAnimate={() => setDrawerIsOpen(false)}
              >
                {label}
              </StyledNavElement>
            </ListItem>
          ))}
        </List>
        <Box display="flex" justifyContent={`center`}>
          <SocialLinks />
        </Box>
      </Drawer>
    </>
  )
}

Navigation.propTypes = {
  yAxis: PropTypes.object,
  children: PropTypes.node,
}

export default Navigation
