import React from "react"
import PropTypes from "prop-types"
import { Container, Grid, Box, Typography, useMediaQuery } from "@mui/material"
import { useTheme } from "@mui/material/styles"
import { StaticImage } from "gatsby-plugin-image"

import { ThemeScrollAnimation } from "../ThemeScrollAnimation"
import { Segment, Section } from "../Layout"
import { SectionTitle } from "../SectionTitle"

const GoGreen = ({ children }) => {
  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.between("xs", "md"))

  return (
    <>
      <div style={{ display: "grid" }}>
        <StaticImage
          style={{
            gridArea: "1/1",
            maxHeight: 600,
          }}
          layout="fullWidth"
          aspectRatio={2 / 1}
          alt=""
          src="../../images/hero-gogreen.jpg"
          formats={["auto", "webp", "avif"]}
        />
        <div
          style={{
            gridArea: "1/1",
            position: "relative",
            justifyItems: "center",
            alignItems: "flex-start",
            display: "grid",
          }}
        >
          <Section>
            <ThemeScrollAnimation animateIn="fadeInUp">
              <Box mt={isSmall ? "4rem" : "8rem"}>
                <Typography variant="h2" color="common.white" align="center">
                  Touchless Dishware
                </Typography>
                <Typography variant="h4" color="common.white" align="center">
                  Coming Soon!
                </Typography>
              </Box>
            </ThemeScrollAnimation>
          </Section>
        </div>
      </div>
      <Segment>
        <Container disableGutters>
          {/* <ThemeScrollAnimation animateIn="fadeIn">
            <Box>
              <SegmentTitle>Go Green</SegmentTitle>
            </Box>
          </ThemeScrollAnimation> */}
          <Section>
            <Grid container>
              <Grid item xs={12} md={5}>
                <ThemeScrollAnimation animateIn="fadeInUp">
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      pr: isSmall ? 0 : 4,
                    }}
                    mb={isSmall ? 4 : 0}
                  >
                    <StaticImage
                      src="../../images/img-delray-beach.jpg"
                      alt="nature"
                      placeholder="blurred"
                      width={494}
                      height={335}
                    />
                  </Box>
                </ThemeScrollAnimation>
              </Grid>
              <Grid item xs={12} md={7}>
                <Box>
                  <ThemeScrollAnimation animateIn="fadeInUp">
                    <SectionTitle align={isSmall ? "center" : "initial"}>
                      Go Touchless Lifestyle!
                    </SectionTitle>
                    {/* <SectionTitle
                      align={isSmall ? "center" : "initial"}
                      subtitle
                      gutterBottom
                    >
                      
                    </SectionTitle> */}
                  </ThemeScrollAnimation>
                  <ThemeScrollAnimation animateIn="fadeInUp">
                    <Typography
                      align={isSmall ? "center" : "initial"}
                      pr={isSmall ? 0 : "64px"}
                      paragraph
                    >
                      The “Go Touchless!” way of living is a lifestyle that
                      tries in as many ways as it can to bring into balance the
                      conservation and preservation of the Earth's natural
                      resources, habitats, and biodiversity with human culture
                      and communities.
                    </Typography>
                    <Typography
                      variant="h5"
                      align={isSmall ? "center" : "initial"}
                      pr={isSmall ? 0 : "64px"}
                      paragraph
                    >
                      We at Go Touchless believe in the lifestyle to “reduce,
                      reuse and recycle."
                    </Typography>
                    <Typography
                      align={isSmall ? "center" : "initial"}
                      pr={isSmall ? 0 : "64px"}
                      paragraph
                    >
                      "Going green involves taking steps to minimize the damage
                      humans are doing; to live an environmentally responsible
                      life, and to make choices that will help preserve the
                      earth and its non-renewable resources instead of
                      destroying them.”
                    </Typography>
                    {/* <Box>
                      <Center>
                        <ThemeButton>Non eum atque</ThemeButton>
                      </Center>
                    </Box> */}
                  </ThemeScrollAnimation>
                </Box>
              </Grid>
            </Grid>
          </Section>
        </Container>
      </Segment>
    </>
  )
}

GoGreen.propTypes = {
  children: PropTypes.node,
}

export default GoGreen
