import React from "react"
import { rem } from "polished"
import { Container, Box, Grid, Typography, useMediaQuery } from "@mui/material"
import { useTheme } from "@mui/material/styles"
import { SocialLinks } from "../SocialLinks"
import { StyledFooter } from "./Footer.styled"

import WebLogoWhite from "../../images/web-logo-white.svg"

const Footer = () => {
  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"))
  const year = new Date()
  return (
    <StyledFooter>
      <Container disableGutters>
        <Box sx={{ px: isSmall ? 2 : 0 }}>
          <Grid container>
            <Grid item xs={12} md={7}>
              <Box
                display="flex"
                justifyContent={isSmall ? "center" : "flex-start"}
                sx={{ pt: isSmall ? `${rem(32)}` : "initial" }}
              >
                <Box
                  component="img"
                  src={WebLogoWhite}
                  alt="web logo white"
                  sx={{
                    width: `${rem(180)}`,
                    mb: isSmall ? `${rem(32)}` : "initial",
                  }}
                />
              </Box>
            </Grid>
            {!isSmall && (
              <Grid item xs={12} md={5}>
                <Box display="flex" justifyContent="flex-end">
                  <SocialLinks white />
                </Box>
              </Grid>
            )}
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              <Box
                sx={{
                  height: "36px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Typography align="center" color={theme.palette.common.white}>
                  {`Copyright © ${year.getFullYear()} Touchless Flatware Corporation. All rights
                  reserved.`}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </StyledFooter>
  )
}

export default Footer
