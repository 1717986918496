import React from "react"
import { styled } from "@mui/system"
import { rem } from "polished"
import { Scrollchor, easeOutQuad } from "react-scrollchor"

import { headlineFont } from "../../theme/theme-config"

const StyledThemeScrollButton = styled(({ children, ...otherProps }) => (
  <Scrollchor animate={{ duration: 1000, easing: easeOutQuad }} {...otherProps}>
    {children}
  </Scrollchor>
))(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  fontFamily: headlineFont,
  textDecoration: "none",
  fontWeight: 500,
  fontSize: "0.875rem",
  lineHeight: 1.75,
  textTransform: "uppercase",
  minWidth: `${rem(64)}`,
  padding: `${rem(12)} ${rem(16)}`,
  borderRadius: `4px`,
  transition: `background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms`,
  color: `rgba(255, 255, 255, 0.87)`,
  boxShadow: `0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)`,
}))

export { StyledThemeScrollButton }
