import { styled } from "@mui/system"
import { Box } from "@mui/material"

const StyledVideoBackground = styled(Box)(({ theme }) => ({
  objectFit: "cover",
  height: "100vh",
  position: "absolute",
  top: 0,
  left: 0,
  zIndex: -1,
}))

export { StyledVideoBackground }
