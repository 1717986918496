import React from "react"
import {
  Grid,
  Typography,
  Container,
  Box,
  TextField,
  useMediaQuery,
} from "@mui/material"
import Radio from "@mui/material/Radio"
import RadioGroup from "@mui/material/RadioGroup"
import FormControlLabel from "@mui/material/FormControlLabel"
import FormControl from "@mui/material/FormControl"
import { useTheme } from "@mui/material/styles"
import { StaticImage } from "gatsby-plugin-image"

import { ThemeScrollAnimation } from "../ThemeScrollAnimation"
import { ThemeButton } from "../ThemeButton"
import { SocialLinks } from "../SocialLinks"
import { SegmentTitle } from "../SegmentTitle"

const ContactHero = () => {
  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"))

  return (
    <div style={{ display: "grid" }}>
      {/* You can use a GatsbyImage component if the image is dynamic */}
      <StaticImage
        style={{
          gridArea: "1/1",

          // You can set a maximum height for the image, if you wish.
          // maxHeight: 600,
        }}
        layout="fullWidth"
        // You can optionally force an aspect ratio for the generated image
        aspectRatio={2 / 1}
        // This is a presentational image, so the alt should be an empty string
        alt=""
        // Assisi, Perúgia, Itália by Bernardo Ferrari, via Unsplash
        src="../../images/hero-contact.jpg"
        formats={["auto", "webp", "avif"]}
      />
      <div
        style={{
          // By using the same grid area for both, they are stacked on top of each other
          gridArea: "1/1",
          position: "relative",
          // This centers the other elements inside the hero component
          justifyItems: "center",
          alignItems: "flex-start",
          display: "grid",
        }}
      >
        {/* Any content here will be centered in the component */}
        <Box sx={{ width: "100%", p: 4 }}>
          <Container disableGutters>
            <Grid container mb={4}>
              <Grid item xs={12}>
                <ThemeScrollAnimation animateIn="fadeInUp">
                  <Box>
                    <SegmentTitle align="center">Contact</SegmentTitle>
                  </Box>
                </ThemeScrollAnimation>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} md={2}></Grid>
              <Grid item xs={12} md={8}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={9}>
                    <ThemeScrollAnimation animateIn="fadeInUp">
                      <form
                        name="contact"
                        method="POST"
                        action="/thank-you"
                        data-netlify="true"
                        data-netlify-honeypot="bot-field"
                      >
                        <input type="hidden" name="bot-field" />
                        <input type="hidden" name="form-name" value="contact" />
                        <Box mb={2}>
                          <TextField
                            id="name"
                            label="Your Name"
                            name="name"
                            required
                            variant="filled"
                            fullWidth
                            color="secondary"
                          />
                        </Box>
                        <Box mb={2}>
                          <TextField
                            id="phone-number"
                            label="Your Phone Number"
                            name="phone-number"
                            required
                            variant="filled"
                            fullWidth
                          />
                        </Box>
                        <Box mb={2}>
                          <TextField
                            id="email"
                            label="Your Email"
                            name="email"
                            required
                            variant="filled"
                            fullWidth
                          />
                        </Box>
                        <Box mb={2}>
                          <FormControl>
                            <RadioGroup
                              row
                              aria-labelledby="contact-type"
                              name="contact-type"
                              defaultValue="individual"
                            >
                              <FormControlLabel
                                value="individual"
                                control={<Radio />}
                                label="Individual"
                              />
                              <FormControlLabel
                                value="distributor"
                                control={<Radio />}
                                label="Distributors & Suppliers"
                              />
                            </RadioGroup>
                          </FormControl>
                        </Box>
                        <Box mb={2}>
                          <TextField
                            id="message"
                            label="Message"
                            name="message"
                            multiline
                            rows={4}
                            required
                            variant="filled"
                            fullWidth
                          />
                        </Box>
                        <Box
                          display="flex"
                          justifyContent={isSmall ? "center" : "flex-start"}
                        >
                          <ThemeButton type="submit">
                            Send your message
                          </ThemeButton>
                        </Box>
                      </form>
                    </ThemeScrollAnimation>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Box>
                      <ThemeScrollAnimation animateIn="fadeInUp">
                        <Box>
                          <Typography
                            align={isSmall ? "center" : "initial"}
                            variant={isSmall ? "h5" : "h3"}
                            color="primary"
                          >
                            Get in Touch
                          </Typography>
                          <Typography
                            align={isSmall ? "center" : "initial"}
                            color="primary"
                            variant="subtitle1"
                          >
                            info@touchlessflatware.com
                          </Typography>
                          <Typography
                            align={isSmall ? "center" : "initial"}
                            color="primary"
                            variant="subtitle1"
                          >
                            (800) 822-7126
                          </Typography>
                        </Box>
                        <Box
                          display="flex"
                          justifyContent={isSmall ? "center" : "flex-start"}
                        >
                          <SocialLinks />
                        </Box>
                      </ThemeScrollAnimation>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={2}></Grid>
            </Grid>
          </Container>
        </Box>
      </div>
    </div>
  )
}
export default ContactHero
