import React from "react"
import PropTypes from "prop-types"
import { Container, Grid, Box, Typography, useMediaQuery } from "@mui/material"
import { useTheme } from "@mui/material/styles"
import { StaticImage } from "gatsby-plugin-image"
import Gallery from "react-photo-gallery"

import { ThemeScrollAnimation } from "../ThemeScrollAnimation"
import { Segment, Section } from "../Layout"
import { SectionTitle } from "../SectionTitle"
import { ThemeButton } from "../ThemeButton"

import LogoChopsticks from "../../images/logo-chopsticks.svg"

import ChopsticksGal01 from "../../images/gallery-chopsticks/01.jpg"
import ChopsticksGal02 from "../../images/gallery-chopsticks/02.jpg"
import ChopsticksGal03 from "../../images/gallery-chopsticks/03.jpg"

const photosChopsticks = [
  {
    src: ChopsticksGal01,
    width: 5,
    height: 3,
  },
  {
    src: ChopsticksGal02,
    width: 5,
    height: 3,
  },
  {
    src: ChopsticksGal03,
    width: 5,
    height: 3,
  },
]

const Chopsticks = ({ children }) => {
  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.between("xs", "md"))

  return (
    <>
      <Segment variant="alternate">
        <Container disableGutters>
          <Section>
            <Grid container>
              <Grid item xs={12} md={5}>
                <ThemeScrollAnimation animateIn="fadeInUp">
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      pr: isSmall ? 0 : 4,
                    }}
                    mb={isSmall ? 4 : 0}
                  >
                    <Box
                      component="img"
                      src={LogoChopsticks}
                      alt="logo chopsticks"
                    />
                    {/* <StaticImage
                      src="../../images/logo-chopsticks.svg"
                      alt="touchless chopsticks logo"
                      placeholder="blurred"
                      width={494}
                      height={335}
                    /> */}
                  </Box>
                </ThemeScrollAnimation>
              </Grid>
              <Grid item xs={12} md={7}>
                <Box>
                  <ThemeScrollAnimation animateIn="fadeInUp">
                    <SectionTitle align={isSmall ? "center" : "initial"}>
                      Touchless Chopsticks
                    </SectionTitle>
                    {/* <SectionTitle
                      align={isSmall ? "center" : "initial"}
                      subtitle
                      gutterBottom
                    >
                      The hygienic, high-tech utensil of the future
                    </SectionTitle> */}
                  </ThemeScrollAnimation>
                  <ThemeScrollAnimation animateIn="fadeInUp">
                    <Typography
                      align={isSmall ? "center" : "initial"}
                      pr={isSmall ? 0 : "64px"}
                      paragraph
                    >
                      Different aspects of Chinese Style chopsticks, Japanese
                      style chopsticks and Korean style chopsticks have all
                      influenced the style and design of the new Touchless Style
                      Chopsticks. Touchless Chopsticks are a novel solution to
                      keep the utensil tips from touching the table.
                    </Typography>
                    <Box
                      display="flex"
                      justifyContent={isSmall ? "center" : "flex-start"}
                    >
                      <ThemeButton
                        onClick={() =>
                          window.open(
                            "https://www.touchlesschopsticks.com",
                            "_blank"
                          )
                        }
                      >
                        Learn More
                      </ThemeButton>
                    </Box>
                  </ThemeScrollAnimation>
                </Box>
              </Grid>
            </Grid>
          </Section>
        </Container>
      </Segment>
      <ThemeScrollAnimation animateIn="fadeInUp">
        <Gallery photos={photosChopsticks} />
      </ThemeScrollAnimation>
    </>
  )
}

Chopsticks.propTypes = {
  children: PropTypes.node,
}

export default Chopsticks
