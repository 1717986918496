import React from "react"
import PropTypes from "prop-types"
import { Grid, Box, Typography, useTheme, useMediaQuery } from "@mui/material"

import { VideoBackground } from "../VideoBackground"
import { Section } from "../Layout"
import { ThemeScrollAnimation } from "../ThemeScrollAnimation"

import BgVideo from "../../video/Touchless_MontageLoop_web.mp4"

const HomeHeroDesktop = () => {
  const theme = useTheme()

  const isMedium = useMediaQuery(theme.breakpoints.down("lg"))
  return (
    <>
      <VideoBackground src={BgVideo} height={isMedium ? 600 : 900}>
        <Section>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <ThemeScrollAnimation animateIn="fadeInUp">
                <Box>
                  <Typography variant="h2" color="common.white" align="center">
                    Innovative Elegance:
                  </Typography>
                  <Typography variant="h4" color="secondary" align="center">
                    Hygenic Utensils for Healthy Living
                  </Typography>
                </Box>
              </ThemeScrollAnimation>
            </Grid>
          </Grid>
        </Section>
      </VideoBackground>
    </>
  )
}

HomeHeroDesktop.propTypes = {
  children: PropTypes.node,
}

export default HomeHeroDesktop
