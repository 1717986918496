import React, { useState, useRef, useLayoutEffect } from "react"
import { Box } from "@mui/material"

import { About } from "../About"
import { ContactHero } from "../Contact"
import { Home } from "../Home"
import { TouchlessSpirit } from "../TouchlessSpirit"
import { FlatwareChopsticks } from "../FlatwareChopsticks"
import { Chopsticks } from "../Chopsticks"
import { GoGreen } from "../GoGreen"

import { Layout } from "../Layout"

const ScrollContainer = () => {
  const refHome = useRef()
  const refAbout = useRef()
  const refContact = useRef()
  const refTouchlessSpirit = useRef()
  const refFlatwareChopsticks = useRef()
  const refChopsticks = useRef()
  const refGoGreen = useRef()

  const [posHome, setPosHome] = useState(null)
  const [posAbout, setPosAbout] = useState(null)
  const [posContact, setPosContact] = useState(null)
  const [posTouchlessSpirit, setPosTouchlessSpirit] = useState(null)
  const [posFlatwareChopsticks, setPosFlatwareChopsticks] = useState(null)
  const [posChopsticks, setPosChopsticks] = useState(null)
  const [posGoGreen, setPosGoGreen] = useState(null)

  const yAxisObj = {
    posHome,
    posAbout,
    posContact,
    posTouchlessSpirit,
    posFlatwareChopsticks,
    posChopsticks,
    posGoGreen,
  }

  const topPos = "-65px"

  useLayoutEffect(() => {
    function updatePosition() {
      setPosHome(refHome.current.offsetTop)
      setPosAbout(refAbout.current.offsetTop)
      setPosContact(refContact.current.offsetTop)
      setPosTouchlessSpirit(refTouchlessSpirit.current.offsetTop)
      setPosFlatwareChopsticks(refFlatwareChopsticks.current.offsetTop)
      setPosChopsticks(refChopsticks.current.offsetTop)
      setPosGoGreen(refGoGreen.current.offsetTop)
    }
    window.addEventListener("resize", updatePosition)
    updatePosition()
    return () => window.removeEventListener("resize", updatePosition)
  }, [])

  return (
    <>
      <section id="home" ref={refHome}></section>
      <Layout yAxis={yAxisObj}>
        <section>
          <Home />
        </section>
        <Box sx={{ position: "relative" }} ref={refTouchlessSpirit}>
          <Box
            id="touchless-spirit"
            sx={{ position: "absolute", top: topPos }}
          ></Box>
          <TouchlessSpirit />
        </Box>
        <Box sx={{ position: "relative" }} ref={refFlatwareChopsticks}>
          <Box id="flatware" sx={{ position: "absolute", top: topPos }}></Box>
          <FlatwareChopsticks />
        </Box>
        <Box sx={{ position: "relative" }} ref={refChopsticks}>
          <Box id="chopsticks" sx={{ position: "absolute", top: topPos }}></Box>
          <Chopsticks />
        </Box>
        <Box sx={{ position: "relative" }} ref={refGoGreen}>
          <Box id="go-green" sx={{ position: "absolute", top: topPos }}></Box>
          <GoGreen />
        </Box>
        <Box sx={{ position: "relative" }} ref={refAbout}>
          <Box id="about" sx={{ position: "absolute", top: topPos }}></Box>
          <About />
        </Box>
        <Box sx={{ position: "relative" }} ref={refContact}>
          <Box id="contact"></Box>
          <ContactHero />
        </Box>
      </Layout>
    </>
  )
}
export default ScrollContainer
