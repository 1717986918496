import { styled } from "@mui/system"
import { rem } from "polished"
import { Box, AppBar } from "@mui/material"
import { Scrollchor } from "react-scrollchor"

const StyledAppBar = styled(AppBar)(({ appBarWhite, theme }) => ({
  backgroundColor: appBarWhite ? "white" : "initial",
  transition: "all 500ms",
  [theme.breakpoints.down("xs")]: {
    backgroundColor: "white",
  },
  [theme.breakpoints.up("sm")]: {
    backgroundColor: "white",
  },
}))
const StyledNavigation = styled(Box)({
  padding: `${rem(1)}`,
})

const StyledNavElement = styled(Scrollchor)(({ isactivelink, theme }) => ({
  fontFamily: "'Quicksand', sans-serif",
  textDecoration: "none",
  color: theme.palette.secondary.main,
  fontWeight: "bold",
  transition: "all 200ms",
  borderBottom: isactivelink
    ? `2px solid ${theme.palette.primary.main}`
    : "transparent",
  "&:hover": {
    cursor: "pointer",
    color: theme.palette.secondary.main,
  },
  padding: "0.5rem",
  [theme.breakpoints.up("xl")]: {
    color: theme.palette.secondary.main,
  },
}))

export { StyledAppBar, StyledNavigation, StyledNavElement }
